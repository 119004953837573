@use "../../../styles/variables.scss" as *;

.root {
  color: $color-gray-darken-2;
  fill: $color-gray-darken-2;
  margin-top: 2.4rem;
  display: flex;
  padding: 0;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: flex-end;
}

.attachments {
  flex-grow: 1;
}

.errors {
  width: 100%;
}

.error {
  color: $color-notification;
  font-size: 1.2rem;
  margin-top: 0.8rem;
}

.content {
  margin-right: 1rem;
  flex-grow: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
}

.content__withFile {
  border-radius: 0.5rem;
  border: 1px solid $color-gray-lighten-1;
  outline: 1px solid $color-primary;
  &.fieldError {
    border: 1px solid $color-notification;
    background: #fbf0f0;
    outline: none;
  }
}

.content__input {
  line-height: 1.6;
  resize: vertical;
  padding-right: 3.8rem;
}

.content__inputWithFile {
  border: none;
  outline: none;
  &:focus {
    border: none;
    outline: none;
  }
}

.content__inputDisabled {
  resize: none;
}

.imageAttachmentsAttributes {
  display: none;
}

.filename {
  margin-bottom: 1.6rem;
  display: inline-block;
}

.imageIcon {
  position: absolute;
  right: 1.4rem;
  top: 0.8rem;
}

.button {
  width: 10.8rem;
  height: 4.6rem;
}

@include max-screen($breakpoint-mobile) {
  .root {
    display: block;
  }

  .content {
    margin-right: 0;
  }

  .button {
    margin: 1.4rem auto;
  }
  .error {
    margin-bottom: 0.6rem;
  }
  .content__file.fieldError {
    border: 1px solid $color-gray-lighten-1;
  }
}
